<template>
  <div>
    <div class="mt-1">
      <!-- cards -->
      <section class="py-2">
        <div
          class="
            head
            my-1
            d-md-flex
            justify-content-end justify-content-md-between
            align-items-center
          "
        >
          <b-form
            class="input-search mb-3"
            @submit.prevent="handleSearch(search)"
          >
            <b-form-input
              @input="handleSearch"
              v-model="search"
              placeholder="Digite para pesquisar"
              class="col-3 mb-2"
            ></b-form-input>
            <b-button
              type="submit"
              variant="light"
              class="px-2 d-flex align-items-center absolute icon-action"
            >
              <b-icon icon="search"></b-icon>
            </b-button>
          </b-form>

          <b-button
            v-permission="['admin', 'borrower']"
            @click="handleOpenFormHomologation(null)"
            class="btn-add"
          >
            Adicionar
          </b-button>
        </div>
        <skeleton grid="3" gap="12" :rows="6" v-if="isBussy" height="350px" />
        <cards-list grid="3" gap="12">
          <b-card
            class="mb-2 card-hoverable"
            v-for="(item, index) in items"
            :key="index"
          >
            <div class="w-100 d-flex justify-content-between">
              <router-link :to="`/homologation/${item.id}/service/list`">
                <h5 class="d-block mb-2">
                  {{ item.name }}
                </h5>

                <b-badge
                  :variant="getStatus(item.status).variant"
                  class="mt-md-0 mt-2 d-table"
                >
                  {{ item.status_display }}
                </b-badge>
              </router-link>

              <div
                class="d-flex align-items-start gap-2"
                v-if="item.status === 0"
              >
                <b-button
                  size="sm"
                  class="px-2"
                  variant="outline-dark"
                  @click="handleOpenFormHomologation(item)"
                >
                  <b-icon
                    class="h6 mx-1 pointer mb-0"
                    v-if="!is_provider"
                    icon="pencil"
                  />
                  <span class="text-muted">Editar</span>
                </b-button>
                <b-button
                  v-if="user.is_admin"
                  size="sm"
                  class="px-2"
                  variant="outline-dark"
                  v-b-popover.hover.top="'Excluir'"
                  @click="handleConfirmDelete(item.id)"
                >
                  <b-icon
                    class="h6 mx-1 pointer mb-0"
                    icon="trash"
                    v-if="!is_provider"
                  />
                  <span class="text-muted">Editar</span>
                </b-button>
              </div>
            </div>
            <router-link
              class="w-100 text-muted"
              :to="`/homologation/${item.id}/service/list`"
            >
              <section class="mt-2">
                <div
                  class="
                  w-100
                  justify-content-between
                  align-items-center
                "
                >
                  <div class="borrower">
                    <strong class="text-muted">Tomador: </strong>
                    <p
                      class="mb-0 text-truncate"
                      :title="item.borrower.social_reason"
                    >
                      <b-icon icon="person" class="mr-2" />
                      {{ item.borrower.social_reason }}
                    </p>
                    <p>
                      <b-icon icon="building" class="mr-2" />
                      {{ item.borrower.cnpj | VMask("##.###.###/####-##") }}
                    </p>
                  </div>
                  <div class="mb-2">
                    <strong class="text-muted">Período: </strong>
                    <section class="d-flex align-items-center">
                      <b-icon class="mr-2" icon="calendar" />
                      <span> {{ item.date_init | date }}</span>
                    </section>

                    <section class="d-flex align-items-center">
                      <b-icon class="mr-2" icon="calendar-check" />
                      <span>{{ item.date_end | date }}</span>
                    </section>
                  </div>
                </div>
                <div>
                  <strong class="d-block"> Localização </strong>
                  <span>
                    <b-icon icon="geo" /> {{ item.city.name }} -
                    {{ item.state.name }}, {{ item.state.uf }}
                  </span>
                </div>
              </section>
            </router-link>
          </b-card>
        </cards-list>
        <empty-list v-if="isEmpty" text="Esta lista está vazia" :size="70">
        </empty-list>
        <div v-else>
          <div class="d-flex align-items-center mt-4">
            <div class="d-flex align-items-center ">
              <span>Mostrando</span>
              <b-form-group class="d-flex mb-0 mx-2 px-1">
                <b-select
                  v-model="per_page"
                  @change="handleChangePerPage"
                  class="select-pagination"
                >
                  <b-select-option value="9">9</b-select-option>
                  <b-select-option value="15">15</b-select-option>
                  <b-select-option value="21">21</b-select-option>
                  <b-select-option value="33">33</b-select-option>
                </b-select>
              </b-form-group>
            </div>
            <span>
              registros de um total de <strong> {{ total }} </strong>
            </span>
          </div>
          <b-pagination
            @change="handleChangePage"
            v-model="current_page"
            :total-rows="total"
            :per-page="per_page"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </section>
    </div>
    <b-modal id="delete-homologations" centered size="sm">
      <template #modal-header> <h5>Cuidado!</h5></template>
      <p>
        Você apagará a homologação. Essa ação não poderá ser desfeita, deseja
        continuar?
      </p>
      <template #modal-footer="{ close }">
        <b-button variant="link" @click="handleDeleteHomologation()">
          Sim
        </b-button>
        <b-button variant="primary" @click="close()">Não</b-button>
      </template>
    </b-modal>

    <b-modal
      hide-footer
      title="Adicionar homologação"
      body-class="min-h-40"
      size="lg"
      centered
      id="form-homologation"
    >
      <form-homologation
        :homologation_id="selectedHomologationId"
        @close="handleClose"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import FormHomologation from "./form.vue";

export default {
  components: {
    FormHomologation,
  },
  data() {
    return {
      selectedHomologation: {},
      selectedHomologationId: "",
      selectedId: null,
      isEmpty: false,
      isBussy: false,
      search: null,
      per_page: 9,
      current_page: 1,
      total: 1,
      items: [],
    };
  },
  computed: {
    ...mapGetters(["user", "is_provider", "is_mobile"]),
  },
  methods: {
    ...mapActions(["get_all_homologation", "delete_homologation"]),

    getStatus(status = 0) {
      let icon;
      let variant;
      let label;
      switch (status) {
        case 0:
          variant = "info";
          label = "Não inicializado";
          break;
        case 1:
          variant = "secondary";
          label = "Em progresso";
          break;
        case 2:
          variant = "success";
          label = "Finalizado";
          break;
      }
      return {
        icon,
        variant,
        label,
      };
    },

    handleOpenFormHomologation(homologation) {
      this.selectedHomologation = homologation;
      this.selectedHomologationId = homologation?.id;
      this.$bvModal.show("form-homologation");
    },

    handleClose() {
      this.$bvModal.hide("form-homologation");
      this.getAllHomologations();
    },

    getAllHomologations() {
      this.isBussy = true;
      this.get_all_homologation({
        per_page: this.per_page,
        current_page: this.current_page,
        search: this.search,
      })
        .then((data) => {
          this.items = data?.results;
          this.total = data.total;
          this.isEmpty = this.total == 0;
        })
        .finally(() => (this.isBussy = false));
    },

    handleChangePage(current_page) {
      this.current_page = current_page;
      this.getAllHomologations();
    },
    handleChangePerPage(per_page) {
      this.per_page = per_page;
      this.getAllHomologations();
    },
    handleSearch: _.debounce(function(value) {
      this.search = value;
      this.getAllHomologations();
    }, 500),

    handleConfirmDelete(id) {
      this.selectedId = id;
      this.$bvModal.show("delete-homologations");
    },

    handleDeleteHomologation() {
      this.delete_homologation(this.selectedId).then(() => {
        this.$bvModal.hide("delete-homologations");
        this.getAllHomologations();
      });
    },
  },
  mounted() {
    this.getAllHomologations();
  },
};
</script>
<style lang="scss" scoped>
.list-homologation {
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: $break-md) {
    grid-template-columns: repeat(1, 1fr);
  }
  .list-group-item {
    flex: 1 1 30%;
    border-top-width: 1px;
    max-width: 30%;

    @media (max-width: $break-md) {
      flex: 1 1 100%;
      margin: 0;
      max-width: 100%;
    }
    &:hover {
      background: var(--bg-2) !important;
    }
  }
}
</style>
