<template>
  <div>
    <b-form @submit.prevent="handleSubmit" class="w-100">
      <div class="row">
        <section class="col-md-12">
          <b-form-group label="Nome:">
            <b-form-input
              :class="{ 'is-invalid': $v.form.name.$error }"
              v-model.trim="$v.form.name.$model"
              placeholder="Nome"
            ></b-form-input>
            <b-form-invalid-feedback v-if="$v.form.name.$error">
              O nome é obrigatório.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-row>
            <b-col md="6">
              <b-form-group label="Data inicial:">
                <b-form-datepicker
                  :date-format-options="dateFormat"
                  required
                  placeholder="00/00/0000"
                  :class="{ 'is-invalid': $v.form.date_init.$error }"
                  v-model.trim="$v.form.date_init.$model"
                  class="mb-2"
                ></b-form-datepicker>
                <b-form-invalid-feedback v-if="$v.form.date_init.$error">
                  Campo obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Data final:">
                <b-form-datepicker
                  :date-format-options="dateFormat"
                  required
                  :class="{
                    'is-invalid':
                      $v.form.date_end.$error && !$v.form.date_end.rangeInit,
                  }"
                  placeholder="00/00/0000"
                  v-model.trim="$v.form.date_end.$model"
                  class="mb-2"
                ></b-form-datepicker>
                <b-form-invalid-feedback
                  v-if="$v.form.date_end.required && $v.form.date_end.rangeInit"
                >
                  Campo obrigatório
                </b-form-invalid-feedback>
                <b-form-invalid-feedback v-if="!$v.form.date_end.rangeInit">
                  A data deve ser maior do que a data de início
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group label="Estado:">
                <multiselect
                  required
                  searchable
                  close-on-select
                  :show-labels="false"
                  :internal-search="false"
                  placeholder="Selecione um Estado"
                  label="name"
                  v-model.trim="$v.form.state.$model"
                  :options="stateOptions"
                  @input="handleSelectCity"
                  @search-change="handleSearchState"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Cidade:">
                <multiselect
                  required
                  searchable
                  close-on-select
                  :show-labels="false"
                  placeholder="Ex: Estrela Dalva"
                  label="name"
                  v-model.trim="$v.form.city.$model"
                  :options="cityOptions"
                  @search-change="handleSearchCity"
                />

                <b-form-invalid-feedback v-if="$v.form.city.$error">
                  Campo obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <!--- Tomador -->
              <b-form-group label="Tomador:">
                <multiselect
                  required
                  :disabled="isBorrower"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder="Selecione um tomador"
                  v-model.trim="$v.form.borrower.$model"
                  :options="borrowerOptions"
                  @input="handleSelectBorrower"
                  @change="handleSelectBorrower"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    <span>{{ option.social_reason }} </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <span>{{ props.option.social_reason }} </span>
                  </template>
                </multiselect>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="form.borrower_responsible">
              <b-form-group label="Responsável legal:">
                <b-form-input
                  :class="{
                    'is-invalid': $v.form.borrower_responsible.$error,
                  }"
                  disabled
                  :value="form.borrower_responsible.name"
                  required
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </section>
      </div>

      <div class="action d-flex justify-content-end w-100 gap-3">
        <b-button
          @click="$emit('close')"
          :disabled="loading_submit"
          variant="light"
        >
          <span>Cancelar</span>
        </b-button>
        <b-button :disabled="loading_submit" type="submit" variant="primary">
          <b-spinner v-if="loading_submit" small class="mr-2" />
          <span>Salvar</span>
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import { api } from "@/services/api";
import { normalize } from "@/utils/normalize";
import _ from "lodash";

export default {
  mixins: [validationMixin],

  props: {
    homologation_id: {
      required: true,
    },
  },

  data() {
    return {
      stateOptions: [],
      cityOptions: [],
      loading_submit: false,
      borrowerOptions: [],
      legalBorrowerOptions: [],
      dateFormat: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      isBorrower: false,
      isLoading: false,
      form: {
        name: null,
        date_init: this.setDate(),
        date_end: this.setDate(),
        segment: null,
        borrower: {
          borrower_responsible: {},
        },
        template: null,
        state: null,
        city: null,
        borrower_responsible: null,
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      date_init: {
        required,
      },
      date_end: {
        required,
        rangeInit(val, { date_init }) {
          return new Date(val) >= new Date(date_init);
        },
      },
      borrower: {
        required,
      },
      state: {
        required,
      },
      city: {
        required,
      },
      borrower_responsible: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions([
      "add_homologation",
      "get_homologation",
      "edit_homologation",
      "show_toast",
      "get_all_borrower",
      "get_all_segment",
      "get_all_templates",
    ]),

    setDate() {
      return this.moment(new Date()).format("YYYY-MM-DD");
    },

    handleSubmit() {
      this.$v.form.$touch();
      if (!this.$v.form.$anyError && !this.$v.$anyError) {
        this.loading_submit = true;
        this.isLoading = true;
        const form = {
          ...this.form,
          borrower: this.form.borrower.id,
          borrower_responsible: this.form.borrower_responsible.id,
          city: this.form.city.id,
          state: this.form.state.id,
          segment: this.form?.segment?.id || "",
        };
        let api = () => undefined;
        if (this.homologation_id) {
          api = this.edit_homologation({ ...form });
        } else {
          api = this.add_homologation({ ...form });
        }
        api
          .then(() => {
            this.show_toast({
              message: "Cadastro realizado com sucesso",
              type: "success",
            });
            this.$emit("close");
            this.loading_submit = false;
          })
          .catch((err) => {
            this.loading_submit = false;
            this.show_toast({
              message: err,
              type: "error",
            });
          });
      }
    },

    getEdit() {
      this.get_homologation({ id: this.homologation_id }).then(({ data }) => {
        this.form = data;
        this.handleSelectCity(this.form.state);
      });
    },

    handleSelectBorrower(borrower) {
      this.form.borrower_responsible = borrower.responsibles[0];
    },

    getBorrowers() {
      this.get_all_borrower({
        is_finalized: true,
        has_responsibles: true,
      }).then((results) => {
        this.borrowerOptions = results.data.results;
        this.isBorrower = this.user.role === "borrower";
        if (this.isBorrower) {
          this.form.borrower = results.data.results[0];
          this.handleSelectBorrower(this.form.borrower);
        }
      });
    },

    handleSearchCity: _.debounce(function(search) {
      this.handleSelectCity({ search });
    }, 500),

    handleSearchState: _.debounce(function(search) {
      const key = normalize(search);

      if (key.length === 0) {
        this.getStates();
      }

      this.stateOptions = this.stateOptions.filter((state) =>
        normalize(state.name).includes(key)
      );
    }, 50),

    getStates(params) {
      api.get("/state/", { ...params }).then(({ data }) => {
        this.stateOptions = data.results;
      });
    },

    handleSelectCity(params) {
      const uf = this.form.state?.uf;
      params.per_page = 854;
      api.get("/city/", { uf, ...params }).then(({ data }) => {
        this.cityOptions = data.results;
      });
    },
  },
  mounted() {
    if (this.homologation_id) {
      this.getEdit();
    }
    this.getBorrowers();
    this.getStates();
  },
};
</script>
